<template>
  <div class="container auth-container">
    <div class="row">
      <div
        style="position: relative; max-width: 33% !important; overflow: hidden"
        class="col-4 left-col left-auth"
        id="divid"
      >
        <embed :src="tvurl" style="width: 430px; height: 100%" />
      </div>

      <div class="right-col text-center">
        <div class="header">
          <a class="header__link">
            <div>
              <img
                width="200px"
                height="180px"
                class="brandlogo"
                :src="brandlogourl"
              />
            </div>
            <div class="wtv-logo-container">
              <div class="circle"><span>W TV</span></div>
            </div>
            <!--<div>
              <img
                width="80px"
                height="70px"
                class="wtvlogo"
                src=".././assets/icons/wtv-icon.svg"
              />
            </div>-->
          </a>

          <p class="header__info">LOGIN</p>
        </div>

        <div class="options" v-if="!otpSent">
          <!-- <div class="options__first">
            <button
              v-if="!isPhoneInputVisible && !phoneSelected"
              class="btn btn-primary btn-block auth-btn"
              @click="openPhoneInput"
            >
              Login With Phone
            </button>
          </div>
          <div class="form-label-group" v-if="isPhoneInputVisible">
            <input
              name="phoneNumber"
              id="phoneNumber"
              class="form-control"
              placeholder="Enter Phone Number"
              v-model="phoneNumber"
            />
          </div>
          <div class="options__first" v-if="phoneSelected">
            <button class="btn btn-primary btn-block auth-btn" @click="sendOTP">
              Send Code
            </button>
          </div> -->
          <div style="height: 12px"></div>
          <div class="form-label-group" v-if="isEmailInputVisible">
            <input
              name="email"
              id="email"
              class="form-control"
              placeholder="Enter Email"
              v-model="email"
            />
          </div>
          <div class="options__first" v-if="emailSelected">
            <button class="btn btn-primary btn-block auth-btn" @click="sendOTP">
              Send Code
            </button>
          </div>
          <div
            class="options__first"
            v-if="!isEmailInputVisible && !emailSelected"
          >
            <button
              class="btn btn-primary btn-block auth-btn"
              @click="openEmailInput"
            >
              Login With Email
            </button>
          </div>
        </div>
        <div class="form-label-group" v-if="otpSent">
          <input
            type="password"
            name="otp"
            id="otp"
            class="form-control"
            placeholder="Enter OTP"
            v-model="otp"
          />
        </div>
        <div class="options__first" v-if="otpSent">
          <button
            class="btn btn-primary btn-block auth-btn"
            @click="nextPage()"
          >
            Verify OTP
          </button>
        </div>
      </div>
      <div
        style="
          position: relative;
          padding-left: 0px !important;
          padding-right: 0px !important;
          overflow: hidden;
        "
        class="col-4 left-col left-auth"
        id="divid"
      >
        <embed :src="tvurl" style="width: 430px; height: 100%" />
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
Vue.use(Loading);
Vue.use(VueAxios, axios);
import VueSimpleAlert from "vue-simple-alert";
Vue.use(VueSimpleAlert);
import AgoraRTC from "agora-rtc-sdk";
import AgoraRTM from "agora-rtm-sdk";

export default {
  name: "Home",
  props: {},
  data() {
    return {
      isPhoneInputVisible: false,
      isEmailInputVisible: false,
      phoneNumber: "",
      email: "",
      phoneSelected: false,
      emailSelected: false,
      otpSent: false,
      myloader: "",
      otp: "",
      mode: "",
    };
  },
  methods: {
    startLoader() {
      this.myloader = this.$loading.show({
        canCancel: false,
        color: "#000000",
      });
    },
    stopLoader() {
      this.myloader.hide();
    },
    async sendOTP() {
      if (this.isPhoneInputVisible) {
        if (this.phoneNumber == "") {
          alert("Mobile Number is mandatory");
          return;
        } else if (this.phoneNumber.length != 10) {
          alert("Mobile Number is invalid");
          return;
        }
      }
      if (this.isEmailInputVisible) {
        if (this.email == "") {
          alert("Email is mandatory");
          return;
        }
      }

      const headers = {
        apiKey: sessionStorage.getItem("apikey"),
        deviceId: sessionStorage.getItem("deviceId"),
        userId: sessionStorage.getItem("userId"),
      };
      if (this.isPhoneInputVisible) {
        var phoneNo = "+91" + this.phoneNumber;
        this.startLoader();
         axios
          .post(
            `https://api.wakaw.live/v1/otp`,
            {
              phoneNumber: phoneNo,
              type: "sms",
            },
            { headers: headers }
          )
          .then((res) => {
            this.stopLoader();
            console.log("OTP Post", res);
              this.mode = "M";
              this.otpSent = true;
              this.isPhoneInputVisible = false;
              this.isEmailInputVisible = false;
              this.emailSelected = false;
              this.phoneSelected = false;
          }).catch((e)=>{
            this.stopLoader();
            console.log(e);
          });
      } else if (this.isEmailInputVisible) {
        var myemail = this.email;
        this.startLoader();
        await axios
          .post(
            `https://api.wakaw.live/v1/otp`,
            {
              email: myemail,
              type: "email",
            },
            { headers: headers }
          )
          .then((res) => {
            console.log(res);
            this.stopLoader();
             this.otpSent = true;
            this.mode = "E";
            this.isPhoneInputVisible = false;
            this.isEmailInputVisible = false;
            this.emailSelected = false;
            this.phoneSelected = false;
          }).catch(()=>{
            this.stopLoader();
            this.$confirm("Something Went Wrong", "Error", "error");
          });
      }
    },
    openPhoneInput() {
      this.isPhoneInputVisible = true;
      this.isEmailInputVisible = false;
      this.phoneSelected = true;
      this.emailSelected = false;
    },
    openEmailInput() {
      this.isEmailInputVisible = true;
      this.isPhoneInputVisible = false;
      this.emailSelected = true;
      this.phoneSelected = false;
    },
    async nextPage() {
      const headers = {
        apiKey: sessionStorage.getItem("apikey"),
        deviceId: sessionStorage.getItem("deviceId"),
        userId: sessionStorage.getItem("userId"),
      };
      if (
        !(
          headers.deviceId &&
          headers.deviceId.length > 0 &&
          headers.userId &&
          headers.userId.length > 0 &&
          this.mode
        )
      ) {
        this.mode = "";
        this.$router.push({
          path: "/",
        });
      } else if (this.mode == "M") {
        this.startLoader();
        var phoneNo = "+91" + this.phoneNumber;

         axios
          .post(
            `https://api.wakaw.live/v1/authentication/login`,
            {
              phoneNumber: phoneNo,
              otp: "" + this.otp,
              type: "sms",
            },
            { headers: headers }
          )
          .then((response) => {
            this.stopLoader();
            sessionStorage.setItem("accessToken", response.data.data.accessToken);
            sessionStorage.setItem("userId", response.data.data.userId);
              if (
              this.$route.params.videoId != null &&
              this.$route.params.videoId != ""
            ) {
              if (
                this.$route.query.uid != null &&
                this.$route.query.uid != ""
              ) {
                this.$router.push({
                  path:
                    "/Video/" +
                    this.$route.params.videoId +
                    "?uid=" +
                    this.$route.query.uid,
                });
              } else {
                this.$router.push({
                  path: "/Video/" + this.$route.params.videoId,
                });
              }
            } else {
              this.$router.push({ path: "/Video" });
            }
          });
      } else if (this.mode == "E") {
        this.startLoader();
        var myemail = this.email;
        await axios
          .post(
            `https://api.wakaw.live/v1/authentication/login`,
            {
              email: myemail,
              otp: "" + this.otp,
              type: "email",
            },
            { headers: headers }
          )
          .then((response) => {
            console.log('OTP response', response.data.data);
            console.log("Route Data", this.$route);
            this.stopLoader();
            sessionStorage.setItem("accessToken", response.data.data.accessToken);
            sessionStorage.setItem("userId", response.data.data.userId);
            sessionStorage.setItem("userName", response.data.data.username);
              if (this.$route.params.videoId != null && this.$route.params.videoId != "") {
                if ( this.$route.query.uid != null && this.$route.query.uid != "") {
                  this.$router.push({
                    path:
                      "/Video/" +
                      this.$route.params.videoId +
                      "?uid=" +
                      this.$route.query.uid,
                  });
                  // this.joinLiveStreamDirectly();
                } else {
                    this.stopLoader();
                    this.$router.push({
                      path: "/Video/" + this.$route.params.videoId,
                    });
                  }
              } else {
              this.stopLoader();
              this.$router.push({ path: "/Video" });
              
            }
          }).catch((err)=> {
            this.stopLoader();
            this.$confirm("Something Went Wrong", "Error", "error");
            window.location.reload();
            console.log(err);
          })
      }
    },
    async getConfig() {
      await axios
        .get(
          `https://api.wakaw.live/v1/config?appVersionNumber=v1.0.0&appType=customer&appVersionName=android`
        )
        .then((response) => {
          sessionStorage.setItem("apikey", response.data.data.apiKey);
          this.upsertDevice(response.data.data.apiKey);
        })
        .catch(() => {
          this.$confirm("Something Went Wrong", "Error", "error")
          // On refresh error comes
          // this.$confirm("Something Went Wrong", "Error", "error")
          //   .then((r) => {
          //     console.log(r);
          //     console.log("OK selected.");
          //     window.location.reload();
          //   })
          //   .catch(() => {
          //     console.log("OK not selected.");
          //     window.location.reload();
          //   });
        });
    },
    async upsertDevice(key) {
      const headers = {
        apiKey: key,
      };
      await axios
        .post(
          `https://api.wakaw.live/v1/devices`,
          {
            type: "phone",
            osName: "android",
            osVersion: "10.1",
            deviceIdentifier: "9876548527818ert454512",
            application: {
              applicationType: "customer",
              applicationName: "WakaW Customer App",
              applicationVersionName: "new version1",
              applicationVersionNumber: "0.2",
              applicationKey: "com.wakaw.customer",
              deviceToken: "9ghsj7894522",
            },
          },
          { headers: headers }
        )
        .then((response) => {
          sessionStorage.setItem("deviceId", response.data.data.deviceId);
          sessionStorage.setItem("userId", response.data.data.userId);
          sessionStorage.setItem("apikey", response.data.data.apiKey);
        })
        .catch(() => {
          this.$confirm("Something Went Wrong", "Error", "error")
        });
    },

    joinLiveStreamDirectly(){
      const myheaders = {
        deviceId: sessionStorage.getItem("deviceId"),
        userId: sessionStorage.getItem("userId"),
        accessToken: sessionStorage.getItem("accessToken"),
      };
      console.log('headers', myheaders);
      axios
        .post(
          `https://api.wakaw.live/v1/event/join`,
          {
            eventId: this.$route.params.videoId,
          },
          { headers: myheaders }
        )
        .then((response) => {
          console.log('PlayLiveVideo', response);
          this.joinLiveStream(
            response.data.data.channelName,
            response.data.data.token,
            sessionStorage.getItem("userId")
          );
          // this.clientLogin(response.data.data.channelName);
        });
    },

      joinLiveStream(channel, token, userid) {
      console.log("hola world " + channel + " " + token + " " + userid);
      this.streamClient = AgoraRTC.createClient({
        mode: "live",
        codec: "h264",
      });
      this.streamClient.setClientRole(
        "audience",
        function() {
          console.log("setaudience success");
        },
        function(e) {
          console.log("setaudience failed", e);
        }
      );
      //creation of a channel
      this.streamClient.init(
        "467980bc7a524e4c8c3e8e18b7c5caf8",
        () => console.log("AgoraRTC client initialized"),
        this.handleFail
      );
      var vm = this;
      this.subscribeStreamEventsForLiveStreaming();
      this.streamClient.join(
        token,
        channel,
        userid,
        (uid) => {
          console.log(uid);
          vm.isLiveStreamActive = true;
          vm.displayMode = 0;
        },
        vm.handleFail
      );
    },

    clientLogin(channelName) {
      var userId = sessionStorage.getItem("userId");
      const myheaders = {
        deviceId: sessionStorage.getItem("deviceId"),
        userId: sessionStorage.getItem("userId"),
        accessToken: sessionStorage.getItem("accessToken"),
      };
      axios
        .get(`https://api.wakaw.live/v1/rtm/token`, { headers: myheaders })
        .then((res) => {
          let clientObj = AgoraRTM.createInstance(this.appId);
          clientObj
            .login({
              uid: userId,
              token: res.data.data.agoraRtmToken,
            })
            .then(() => {
              let channel = clientObj.createChannel(channelName);
              channel.on("ChannelMessage", function(message, memberId) {
                var msgObj = JSON.parse(message.text);
                console.log("MemberId", memberId);

                let mainContainer = document.getElementById('mainContainer');

                let chip = document.createElement('div');
                chip.setAttribute('style', 'display: inline-flex; background-color: white; border-radius: 10px; padding: 2px 10px; margin: 5px; float: left;'); 
                mainContainer.appendChild(chip);

                let image = document.createElement('img');
                image.src = msgObj.image;
                image.setAttribute('style', 'width: 30px; height: 30px; border-radius: 50%; margin-right: 10px;');
                chip.appendChild(image);

                let textContainer = document.createElement('div');
                chip.appendChild(textContainer);

                let username = document.createElement('div');
                username.append(msgObj.name);
                username.setAttribute('style', 'font-size: 12px; font-weight: bold;');
                textContainer.appendChild(username);

                let text = document.createElement('div');
                text.append(msgObj.message);
                text.setAttribute('style', 'display: block; font-size: 12px;');
                textContainer.appendChild(text);
              });

              channel.join().then(() => {
                  console.log('All Members', channel.getMembers());
                  document.getElementById("send_channel_message").onclick = async () => {
                    var channelMessage = document.getElementById("channelMessage").value;
                    console.log(typeof(channelMessage));
                    var messageObj = {
                      "message": channelMessage,
                      "image": "https://static.vecteezy.com/system/resources/previews/002/318/271/non_2x/user-profile-icon-free-vector.jpg",
                      "name": sessionStorage.getItem('userName')
                    };
                    var messageStr = JSON.stringify(messageObj);
                    if (channel != null) {
                      await channel.sendMessage({text: messageStr, messageType: 'TEXT'}).then(() => {
                let mainContainer = document.getElementById('mainContainer');

                let chip = document.createElement('div');
                chip.setAttribute('style', 'display: inline-flex; background-color: white; border-radius: 10px; padding: 2px 10px; margin: 5px; float: left;'); 
                mainContainer.appendChild(chip);

                let image = document.createElement('img');
                image.src = messageObj.image;
                image.setAttribute('style', 'width: 30px; height: 30px; border-radius: 50%; margin-right: 10px;');
                chip.appendChild(image);

                let textContainer = document.createElement('div');
                chip.appendChild(textContainer);

                let username = document.createElement('div');
                username.append(messageObj.name);
                username.setAttribute('style', 'font-size: 12px; font-weight: bold;');
                textContainer.appendChild(username);

                let text = document.createElement('div');
                text.append(messageObj.message);
                text.setAttribute('style', 'display: block; font-size: 12px;');
                textContainer.appendChild(text);
                        })
                        .catch((err) => {
                          console.log(err);
                        });
                    }
                  };
                })
                .catch((err) => {
                  console.log(err);
                });
            })
            .catch((err) => {
              console.log(err);
            })
            .catch((err) => {
              console.log(err);
            });
        });
    },

  },
  computed: {
    brandlogourl: function () {
      var url = window.location.origin + "/assets/logo/logo.svg";
      return url;
    },
    tvurl: function () {
      var url = window.location.origin + "/TV";
      return url;
    },
  },
  mounted() {
    try {
      if (sessionStorage.getItem("accessToken")) {
        this.$router.push({ path: "/Video" });
      } else {
        try {
          this.getConfig();
        } catch (e) {
          console.log(e);
        }
      }
    } catch (e) {
      console.log(e);
    }
    window.addEventListener("resize", this.myEventHandler);
    screen.orientation.lock("portrait-primary");
  },
  destroyed() {
    window.removeEventListener("resize", this.myEventHandler);
  },
};
</script>

<style scoped>
.row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}
.col-4 {
  flex: 0 0 30%;
  max-width: 30%;
}
.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 0px !important;
  padding-left: 15px;
}

.auth-container .right-col {
  background-color: #fff;
  border: 1px solid #dbdbdb;
  width: 400px;
  float: right;
  padding: 40px;
  padding-top: 30px;
  padding-bottom: 30px;
}
.text-center {
  text-align: center !important;
}
a {
  color: black;
  text-decoration: none;
  background-color: transparent;
}
.header__info {
  font-size: 17px;
  line-height: 25px;
  color: black;
  margin-bottom: 2rem;
  margin-top: 1rem;
}
@media (min-width: 992px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm {
    max-width: 960px;
  }
}

@media (max-width: 992px) {
  .auth-container .right-col {
    margin: 20px auto;
    margin-top: 0px;
    margin-bottom: 0px;
  }
}

.form-control {
  background: #fafafa;
  padding: 8px 0 8px 10px;
  font-size: 14px;
  line-height: 18px;
  min-height: 40px;
  margin-bottom: 1rem;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  font-weight: 400;
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
}
@media (min-width: 992px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm {
    max-width: 960px;
  }
}

@media (min-width: 768px) {
  .container,
  .container-md,
  .container-sm {
    max-width: 1024px;
  }
}
@media (max-width: 765px) {
  .auth-container .right-col {
    background-color: #fafafa;
    border: none;
    padding-top: 5px;
  }
}
@media (min-width: 576px) {
  .container,
  .container-sm {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 100%;
  }
}
@media (max-width: 800px) {
  .left-auth {
    display: none;
  }
  .container {
    /* padding-right: 0px !important; */
  }
}

.container {
  width: 100%;
  /* padding-right: 0px !important;
  padding-left: 0px !important;
  margin-right: 15px !important;
  margin-left: 15px !important; */
}
.btn-primary {
  background-color: black !important;
  border-color: black !important;
}
.btn-primary:hover {
  background-color: black !important;
  border-color: black !important;
}
.btn-primary:active {
  background-color: black !important;
  border-color: black !important;
}
.btn-primary:focus {
  background-color: black !important;
  border-color: black !important;
}
.left-col {
  flex: 10 10 30%;
}
.circle span {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 90px;
  color: #ffffff;
  font-weight: bold;
  font-size: 20px;
}
.circle {
  background: #0b0b0b;
  width: 65px;
  height: 65px;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  margin: 5px;
  position: relative;
}
.wtv-logo-container {
  height: 75px;
  margin-top: 25px;
}
</style>
