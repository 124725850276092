import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '.././components/home.vue'
import Video from '.././components/Video.vue'
import Notfound from '.././components/404.vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import Demo from '.././components/Demo.vue'
import Advertisement from '.././components/Advertisement.vue'


Vue.use(VueRouter)
Vue.use(VueAxios, axios)

function guardMyroute(to, from, next) {
  const myheaders = {
    'deviceId': sessionStorage.getItem('deviceId'),
    'userId': sessionStorage.getItem('userId'),
    'accessToken': sessionStorage.getItem("accessToken"),
  }
  var url = 'https://api.wakaw.live/v1/authentication/validateAndRefresh?accessToken=' + sessionStorage.getItem("accessToken") + '&deviceId=' + sessionStorage.getItem("deviceId") + '&userId=' + sessionStorage.getItem("userId");
  axios.put(url, {}, { headers: myheaders })
    .then(function (response) {
      //console.log(response.data.status)
      if (response.data.status) {
        sessionStorage.setItem('accessToken', response.data.data.accessToken);
        next();
      }
      else {
        sessionStorage.removeItem('accessToken');
        sessionStorage.removeItem('userId');
        sessionStorage.removeItem('deviceId');
        next('/');
      }
    }).catch(e => {
      console.log(e);
      sessionStorage.removeItem('accessToken');
      sessionStorage.removeItem('userId');
      sessionStorage.removeItem('deviceId');
      next('/');
    });
}

const routes = [
  {
    path: '/404notfound',
    component: Notfound,
  },
  {
    path: '/',
    component: Home
  },
  {
    path: '/TV',
    component: Demo
  },
  {
    path: '/Advertisement',
    component: Advertisement
  },
  {
    path: '/Video/:videoId',
    component: Video,
    beforeEnter: guardMyroute,
  },
  {
    path: '/Video',
    component: Video,
    beforeEnter: guardMyroute,
  },
  {
    path: '/:videoId',
    component: Home
  },
  {
    path: '/:videoId/:userID',
    component: Home
  },
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
